<template>
  <div class="home">    
    <div class="select">
      <div class="row select-item">
        <span>日期：</span>
        <div class="block">
          <el-date-picker 
            v-model="timevalue"
            type="daterange" align="right" 
            unlink-panels 
            range-separator="至"
            start-placeholder="开始日期" 
            end-placeholder="结束日期" 
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions">
          </el-date-picker>
        </div>
      </div>
      <div class="row select-item">
        <span>广告计划：</span>
          <el-select v-model="adValue" multiple  collapse-tags placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.adId"
              :label="item.name"
              :value="item.adId">
            </el-option>
          </el-select>
      </div>
      <el-button class="select-item" @click="search()" type="primary">查询</el-button>
      <div class="setting">
        <div class="board" v-show="isShow">
            <div class="close" @click="cancle()">
              <span class="iconfont icon-close"></span>
            </div>
            <div class="check">
              <!-- <div class="left">
                <div class="tab">基础效果</div>
                <div class="tab">成交转化</div>
                <div class="tab">互动效果</div>
              </div> -->
              <div class="right">
                <div class="select-all">
                  <el-checkbox v-model="allSelect" @change="checked => selectAll(checked)">全选</el-checkbox>
                </div>
                <div class="tab-body">
                  <h3>基础效果</h3>
                  <div class="tab-body-item">
                    <template v-for="item in defaultColumns">
                      <div class="check-item" v-if="item.type=='base'" :key="item.prop">
                        <el-checkbox class="column-item" v-model="item.isChecked" @change="checked => changeColumns(checked, item)">
                          {{ item.label }}
                        </el-checkbox>
                      </div>
                    </template>
                  </div>
                </div>
                <div class="tab-body">
                  <h3>成交转化</h3>
                  <div class="total-tran">
                    <h5>总转化</h5>
                    <div class="tab-body-item">
                      <template v-for="item in defaultColumns">
                      <div class="check-item" v-if="item.type=='totalTran'" :key="item.prop">
                        <el-checkbox class="column-item" v-model="item.isChecked" @change="checked => changeColumns(checked, item)">
                          {{ item.label }}
                        </el-checkbox>
                      </div>
                      </template>
                    </div> 
                  </div>
                  <div class="direct-tran">
                    <h5>直接转化</h5>
                    <div class="tab-body-item">
                      <template v-for="item in defaultColumns">
                          <div class="check-item" v-if="item.type=='directTran'" :key="item.prop">
                            <el-checkbox class="column-item" v-model="item.isChecked" @change="checked => changeColumns(checked, item)">
                                  {{ item.label }}
                            </el-checkbox>
                          </div>   
                      </template>
                    </div> 
                  </div>
                  <div class="direct-tran">
                    <h5>间接转化</h5>
                    <div class="tab-body-item">
                      <template v-for="item in defaultColumns">
                      <div class="check-item" v-if="item.type=='indirectTran'" :key="item.prop">
                        <el-checkbox class="column-item" v-model="item.isChecked" @change="checked => changeColumns(checked, item)">
                          {{ item.label }}
                        </el-checkbox>
                      </div>
                      </template>
                    </div> 
                  </div>
                </div>
                <!-- <div class="tab-body">
                  <h3></h3>
                  <div class="tab-body-item">
                    <template v-for="item in defaultColumns">
                      <div class="check-item" v-if="item.type=='base'">
                        <el-checkbox class="column-item" v-model="item.isChecked" @change="checked => changeColumns(checked, item)">
                          {{ item.label }}
                        </el-checkbox>
                      </div>
                    </template>
                  </div>
                </div> -->
              </div>
            </div>
            <el-button size="mini" class="setting-button" @click="cancle()">取消</el-button>
            <el-button type="primary" size="mini" class="setting-button" @click="ensure()">确认</el-button>
         </div>
        <el-button type="text" style="margin: 0 10px;font-size: 20px;" @click="setting()">
          <i class="el-icon-setting"></i>
        </el-button>
      </div>
    </div>
    <div class="tips" style="text-align: left;margin-bottom: 10px"><i class="el-icon-warning"></i>注意：投放情况按照当日是否有消耗决定</div>
    <div class="container">
          <!-- 正文表格 -->
      <el-table id="tableData" 
        class="table-list" 
        ref="table" 
        show-summary 
        :data="tableList" 
        stripe 
        fit 
        style="width: 100%" 
        :height="tableHeight" 
        :key="tableKey" 
        :cell-style="changeStyle"
        :header-cell-style="{background:'#eef1f6',color:'#606266'}" 
        :summary-method="getSummaries">
        <el-table-column
          align="center"
          width="auto"
          prop="time"
          fixed
          label="时间">
        </el-table-column>
        <template v-for="(item, index) in selectColumns">
          <el-table-column  
            :key="index" 
            :label="item.label" 
            v-if="item.isChecked"
            width="auto"
            min-width="150"
            :prop="item.prop"
            align="center"
            :index="item.index" >
            <template slot-scope="scope">
              <span>{{ scope.row[item.prop] }}</span>
            </template>
          </el-table-column>
        </template>
        <!-- <el-table-column
          align="center"
          width="auto"
          prop="proportion"
          label="投放情况">
        </el-table-column> -->
        <!-- <el-table-column 
          v-for="(item, index) in selectColumns" 
          :key="index" 
          :label="item.label" 
          :prop="item.prop"
          v-if="item.isChecked"
          width="auto"
          align="center"
        :index="item.index" >
        </el-table-column> -->
      </el-table>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "@/utils/http.js";
import { math } from '@/utils/math.js'
export default {
  name: "Home",
  data: function () {
    return {
      defaultRateIndex: {
        statCostIndex: null,               //消耗
        showCntIndex: null,                //展示数
        cpmIndex: null,                    //cpm
        clickCntIndex: null,               //点击数
        ctrIndex: null,                    //dianjilv
        payOrderAmountIndex: null,         //直接成交金额
        directPayRoiIndex: null,           //直接成交ROI
        totalPayAmountIndex: null,         //总成交金额
        totalPayRoiIndex: null,            //总成交ROI
        convertCntIndex: null,             //转化数
        convertCostIndex: null,            //转化成本
        convertRateIndex: null,            //转化率
        proportionIndex: null,             //投放情况
      },
      rateIndex: {
        statCostIndex: null,               //消耗
        showCntIndex: null,                //展示数
        cpmIndex: null,                    //cpm
        clickCntIndex: null,               //点击数
        ctrIndex: null,                    //dianjilv
        payOrderAmountIndex: null,         //直接成交金额
        directPayRoiIndex: null,           //直接成交ROI
        totalPayAmountIndex: null,         //总成交金额
        totalPayRoiIndex: null,            //总成交ROI
        convertCntIndex: null,             //转化数
        convertCostIndex: null,            //转化成本
        convertRateIndex: null,            //转化率
        proportionIndex: null,             //投放情况
      },
      defaultRateValue: {
        statCost: 0,
        showCnt: 0,
        clickCnt: 0,
        payOrderAmount: 0,
        totalPayAmount: 0,
        convertCnt: 0,
      },
      rateValue: {
        statCost: 0,
        showCnt: 0,
        clickCnt: 0,
        payOrderAmount: 0,
        totalPayAmount: 0,
        convertCnt: 0,
      },
      tableHeight: 50,
      defaultList: [
        {"id":0,"time":"00-01","statCost":0,"showCnt":0,"cpm":0,"clickCnt":0,"ctr":'0%',"payOrderCount":0,"payOrderAmount":0,"directPayRoi":0,"indirectOrderPayCount7Days":0,"indirectOrderPayGmv7Days":0,"totalOrderCount":0,"totalPayAmount":0,"totalPayRoi":0,"convertCnt":0,"convertCost":0,"convertRate":'0%',"proportion": 0},
        {"id":1,"time":"01-02","statCost":0,"showCnt":0,"cpm":0,"clickCnt":0,"ctr":'0%',"payOrderCount":0,"payOrderAmount":0,"directPayRoi":0,"indirectOrderPayCount7Days":0,"indirectOrderPayGmv7Days":0,"totalOrderCount":0,"totalPayAmount":0,"totalPayRoi":0,"convertCnt":0,"convertCost":0,"convertRate":'0%',"proportion": 0},
        {"id":2,"time":"02-03","statCost":0,"showCnt":0,"cpm":0,"clickCnt":0,"ctr":'0%',"payOrderCount":0,"payOrderAmount":0,"directPayRoi":0,"indirectOrderPayCount7Days":0,"indirectOrderPayGmv7Days":0,"totalOrderCount":0,"totalPayAmount":0,"totalPayRoi":0,"convertCnt":0,"convertCost":0,"convertRate":'0%',"proportion": 0},
        {"id":3,"time":"03-04","statCost":0,"showCnt":0,"cpm":0,"clickCnt":0,"ctr":'0%',"payOrderCount":0,"payOrderAmount":0,"directPayRoi":0,"indirectOrderPayCount7Days":0,"indirectOrderPayGmv7Days":0,"totalOrderCount":0,"totalPayAmount":0,"totalPayRoi":0,"convertCnt":0,"convertCost":0,"convertRate":'0%',"proportion": 0},
        {"id":4,"time":"04-05","statCost":0,"showCnt":0,"cpm":0,"clickCnt":0,"ctr":'0%',"payOrderCount":0,"payOrderAmount":0,"directPayRoi":0,"indirectOrderPayCount7Days":0,"indirectOrderPayGmv7Days":0,"totalOrderCount":0,"totalPayAmount":0,"totalPayRoi":0,"convertCnt":0,"convertCost":0,"convertRate":'0%',"proportion": 0},
        {"id":5,"time":"05-06","statCost":0,"showCnt":0,"cpm":0,"clickCnt":0,"ctr":'0%',"payOrderCount":0,"payOrderAmount":0,"directPayRoi":0,"indirectOrderPayCount7Days":0,"indirectOrderPayGmv7Days":0,"totalOrderCount":0,"totalPayAmount":0,"totalPayRoi":0,"convertCnt":0,"convertCost":0,"convertRate":'0%',"proportion": 0},
        {"id":6,"time":"06-07","statCost":0,"showCnt":0,"cpm":0,"clickCnt":0,"ctr":'0%',"payOrderCount":0,"payOrderAmount":0,"directPayRoi":0,"indirectOrderPayCount7Days":0,"indirectOrderPayGmv7Days":0,"totalOrderCount":0,"totalPayAmount":0,"totalPayRoi":0,"convertCnt":0,"convertCost":0,"convertRate":'0%',"proportion": 0},
        {"id":7,"time":"07-08","statCost":0,"showCnt":0,"cpm":0,"clickCnt":0,"ctr":'0%',"payOrderCount":0,"payOrderAmount":0,"directPayRoi":0,"indirectOrderPayCount7Days":0,"indirectOrderPayGmv7Days":0,"totalOrderCount":0,"totalPayAmount":0,"totalPayRoi":0,"convertCnt":0,"convertCost":0,"convertRate":'0%',"proportion": 0},
        {"id":8,"time":"08-09","statCost":0,"showCnt":0,"cpm":0,"clickCnt":0,"ctr":'0%',"payOrderCount":0,"payOrderAmount":0,"directPayRoi":0,"indirectOrderPayCount7Days":0,"indirectOrderPayGmv7Days":0,"totalOrderCount":0,"totalPayAmount":0,"totalPayRoi":0,"convertCnt":0,"convertCost":0,"convertRate":'0%',"proportion": 0},
        {"id":9,"time":"09-10","statCost":0,"showCnt":0,"cpm":0,"clickCnt":0,"ctr":'0%',"payOrderCount":0,"payOrderAmount":0,"directPayRoi":0,"indirectOrderPayCount7Days":0,"indirectOrderPayGmv7Days":0,"totalOrderCount":0,"totalPayAmount":0,"totalPayRoi":0,"convertCnt":0,"convertCost":0,"convertRate":'0%',"proportion": 0},
        {"id":10,"time":"10-11","statCost":0,"showCnt":0,"cpm":0,"clickCnt":0,"ctr":'0%',"payOrderCount":0,"payOrderAmount":0,"directPayRoi":0,"indirectOrderPayCount7Days":0,"indirectOrderPayGmv7Days":0,"totalOrderCount":0,"totalPayAmount":0,"totalPayRoi":0,"convertCnt":0,"convertCost":0,"convertRate":'0%',"proportion": 0},
        {"id":11,"time":"11-12","statCost":0,"showCnt":0,"cpm":0,"clickCnt":0,"ctr":'0%',"payOrderCount":0,"payOrderAmount":0,"directPayRoi":0,"indirectOrderPayCount7Days":0,"indirectOrderPayGmv7Days":0,"totalOrderCount":0,"totalPayAmount":0,"totalPayRoi":0,"convertCnt":0,"convertCost":0,"convertRate":'0%',"proportion": 0},
        {"id":12,"time":"12-13","statCost":0,"showCnt":0,"cpm":0,"clickCnt":0,"ctr":'0%',"payOrderCount":0,"payOrderAmount":0,"directPayRoi":0,"indirectOrderPayCount7Days":0,"indirectOrderPayGmv7Days":0,"totalOrderCount":0,"totalPayAmount":0,"totalPayRoi":0,"convertCnt":0,"convertCost":0,"convertRate":'0%',"proportion": 0},
        {"id":13,"time":"13-14","statCost":0,"showCnt":0,"cpm":0,"clickCnt":0,"ctr":'0%',"payOrderCount":0,"payOrderAmount":0,"directPayRoi":0,"indirectOrderPayCount7Days":0,"indirectOrderPayGmv7Days":0,"totalOrderCount":0,"totalPayAmount":0,"totalPayRoi":0,"convertCnt":0,"convertCost":0,"convertRate":'0%',"proportion": 0},
        {"id":14,"time":"14-15","statCost":0,"showCnt":0,"cpm":0,"clickCnt":0,"ctr":'0%',"payOrderCount":0,"payOrderAmount":0,"directPayRoi":0,"indirectOrderPayCount7Days":0,"indirectOrderPayGmv7Days":0,"totalOrderCount":0,"totalPayAmount":0,"totalPayRoi":0,"convertCnt":0,"convertCost":0,"convertRate":'0%',"proportion": 0},
        {"id":15,"time":"15-16","statCost":0,"showCnt":0,"cpm":0,"clickCnt":0,"ctr":'0%',"payOrderCount":0,"payOrderAmount":0,"directPayRoi":0,"indirectOrderPayCount7Days":0,"indirectOrderPayGmv7Days":0,"totalOrderCount":0,"totalPayAmount":0,"totalPayRoi":0,"convertCnt":0,"convertCost":0,"convertRate":'0%',"proportion": 0},
        {"id":16,"time":"16-17","statCost":0,"showCnt":0,"cpm":0,"clickCnt":0,"ctr":'0%',"payOrderCount":0,"payOrderAmount":0,"directPayRoi":0,"indirectOrderPayCount7Days":0,"indirectOrderPayGmv7Days":0,"totalOrderCount":0,"totalPayAmount":0,"totalPayRoi":0,"convertCnt":0,"convertCost":0,"convertRate":'0%',"proportion": 0},
        {"id":17,"time":"17-18","statCost":0,"showCnt":0,"cpm":0,"clickCnt":0,"ctr":'0%',"payOrderCount":0,"payOrderAmount":0,"directPayRoi":0,"indirectOrderPayCount7Days":0,"indirectOrderPayGmv7Days":0,"totalOrderCount":0,"totalPayAmount":0,"totalPayRoi":0,"convertCnt":0,"convertCost":0,"convertRate":'0%',"proportion": 0},
        {"id":18,"time":"18-19","statCost":0,"showCnt":0,"cpm":0,"clickCnt":0,"ctr":'0%',"payOrderCount":0,"payOrderAmount":0,"directPayRoi":0,"indirectOrderPayCount7Days":0,"indirectOrderPayGmv7Days":0,"totalOrderCount":0,"totalPayAmount":0,"totalPayRoi":0,"convertCnt":0,"convertCost":0,"convertRate":'0%',"proportion": 0},
        {"id":19,"time":"19-20","statCost":0,"showCnt":0,"cpm":0,"clickCnt":0,"ctr":'0%',"payOrderCount":0,"payOrderAmount":0,"directPayRoi":0,"indirectOrderPayCount7Days":0,"indirectOrderPayGmv7Days":0,"totalOrderCount":0,"totalPayAmount":0,"totalPayRoi":0,"convertCnt":0,"convertCost":0,"convertRate":'0%',"proportion": 0},
        {"id":20,"time":"20-21","statCost":0,"showCnt":0,"cpm":0,"clickCnt":0,"ctr":'0%',"payOrderCount":0,"payOrderAmount":0,"directPayRoi":0,"indirectOrderPayCount7Days":0,"indirectOrderPayGmv7Days":0,"totalOrderCount":0,"totalPayAmount":0,"totalPayRoi":0,"convertCnt":0,"convertCost":0,"convertRate":'0%',"proportion": 0},
        {"id":21,"time":"21-22","statCost":0,"showCnt":0,"cpm":0,"clickCnt":0,"ctr":'0%',"payOrderCount":0,"payOrderAmount":0,"directPayRoi":0,"indirectOrderPayCount7Days":0,"indirectOrderPayGmv7Days":0,"totalOrderCount":0,"totalPayAmount":0,"totalPayRoi":0,"convertCnt":0,"convertCost":0,"convertRate":'0%',"proportion": 0},
        {"id":22,"time":"22-23","statCost":0,"showCnt":0,"cpm":0,"clickCnt":0,"ctr":'0%',"payOrderCount":0,"payOrderAmount":0,"directPayRoi":0,"indirectOrderPayCount7Days":0,"indirectOrderPayGmv7Days":0,"totalOrderCount":0,"totalPayAmount":0,"totalPayRoi":0,"convertCnt":0,"convertCost":0,"convertRate":'0%',"proportion": 0},
        {"id":23,"time":"23-24","statCost":0,"showCnt":0,"cpm":0,"clickCnt":0,"ctr":'0%',"payOrderCount":0,"payOrderAmount":0,"directPayRoi":0,"indirectOrderPayCount7Days":0,"indirectOrderPayGmv7Days":0,"totalOrderCount":0,"totalPayAmount":0,"totalPayRoi":0,"convertCnt":0,"convertCost":0,"convertRate":'0%',"proportion": 0},
      ],
      tableList: [],
      tableHead: null,
      tableKey: 1,
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      timevalue: '',
      // options: [{
      //   value: '1738214403541031',
      //   label: '短视频_SP5_搜索_2022-07-13'
      // }],
      selectedAdArray: [],
      options: [],
      adValue: ['1738214403541031'],
      defaultColumns: [
        // { label: '时间', prop: 'time', type: 'text', isChecked: true },
        { label: ' 消耗', prop: 'statCost', type: 'base', isChecked: true },
        { label: '展示次数', prop: 'showCnt', type: 'base', isChecked: true },
        { label: 'CPM', prop: 'cpm', type: 'base', isChecked: true },
        { label: '点击次数', prop: 'clickCnt', type: 'base', isChecked: true },
        { label: '点击率', prop: 'ctr', type: 'base', isChecked: true },
        { label: '直接成交订单数', prop: 'payOrderCount', type: 'directTran', isChecked: true },
        { label: '直接成交金额', prop: 'payOrderAmount', type: 'directTran', isChecked: true },
        { label: '直接支付ROI', prop: 'directPayRoi', type: 'directTran', isChecked: true },
        { label: '间接成交订单数', prop: 'indirectOrderPayCount7Days', type: 'indirectTran', isChecked: true },
        { label: '间接成交金额', prop: 'indirectOrderPayGmv7Days', type: 'indirectTran', isChecked: true },
        { label: '总订单数', prop: 'totalOrderCount', type: 'totalTran', isChecked: true },
        { label: '总成交金额', prop: 'totalPayAmount', type: 'totalTran', isChecked: true },
        { label: '总支付ROI', prop: 'totalPayRoi', type: 'totalTran', isChecked: true },
        { label: '转化数', prop: 'convertCnt', type: 'base', isChecked: true },
        { label: '转化成本', prop: 'convertCost', type: 'base', isChecked: true },
        { label: '转化率', prop: 'convertRate', type: 'base', isChecked: true },
        { label: '投放情况', prop: 'proportion', type: 'string', isChecked: true },
      ],
      obj: null,
      selectColumns: [],
      tempColumns: [],
      isShow: false,
      renderIndex: 0,
      selectLength: 1      //计算table的列
    }
  },
  created(){
    if (localStorage.getItem('selectColumns')) {
      this.defaultColumns = JSON.parse(localStorage.getItem('selectColumns'))
    }
  },
  computed: {
    // 默认时间
    timeDefault () {
      let date = new Date()
      // 通过时间戳计算
      let defalutStartTime = date.getTime() - 1 * 24 * 3600 * 1000 // 转化为时间戳
      let defalutEndTime = date.getTime() - 1 * 24 * 3600 * 1000 
      let startDateNs = new Date(defalutStartTime) 
      let endDateNs = new Date(defalutEndTime)
      // 月，日 不够10补0
      defalutStartTime = startDateNs.getFullYear() + '-' + ((startDateNs.getMonth() + 1) >= 10 ? (startDateNs.getMonth() + 1) : '0' + (startDateNs.getMonth() + 1)) + '-' + (startDateNs.getDate() >= 10 ? startDateNs.getDate() : '0' + startDateNs.getDate())
      defalutEndTime = endDateNs.getFullYear() + '-' + ((endDateNs.getMonth() + 1) >= 10 ? (endDateNs.getMonth() + 1) : '0' + (endDateNs.getMonth() + 1)) + '-' + (endDateNs.getDate() >= 10 ? endDateNs.getDate() : '0' + endDateNs.getDate())
      return [defalutStartTime, defalutEndTime]
    },
    allSelect:{
        get(){
          let isChecked = true
          this.defaultColumns.forEach(item => {
            if(!item.isChecked){
              isChecked = false
            } 
          })
          return isChecked
        },
        set(newVal){
            return newVal;
        }
    }
  },
  mounted () {
    this.$nextTick(function() {
      this.tableHeight =
        window.innerHeight - this.$refs.table.$el.offsetTop;
      // 监听窗口大小变化
      let self = this;
      window.onresize = function() {
        self.tableHeight =
          window.innerHeight - self.$refs.table.$el.offsetTop;
      };
    });
    // 时间默认值
    this.timevalue = this.timeDefault
    this.selectColumns = JSON.parse(JSON.stringify(this.defaultColumns))
    this.selectLength = 1
    this.selectColumns.forEach(item => {
      if(item.isChecked){
        this.selectLength++
      }  
    })
    this.getAdList()
    this.getData()
  },
  methods: {
    async getAdList(){
      let res = await axios.request({
        url: `planInfos`
      })
      res = res.data
      if(res.code == 500){
        // this.$message('网络开小差');
        this.$message({
          message: '网络开小差',
          type: 'warning'
        });
      }else{
        this.options = JSON.parse(JSON.stringify(res.data))
      }
    },
    async httpData(){
      let list = []
      //选择的日期
      let beginTime = this.timevalue[0]
      let endTime = this.timevalue[1]
      //query选项
      this.selectColumns.forEach(item => {
        if (item.isChecked == true && item.prop != 'time' && item.prop != 'proportion') {
          list.push(item.prop)
        }
      })
      let adQueryList = this.adValue.toString()
      let queryStr
      if (list.length > 0) {
        queryStr = list.toString()
      }else{
        this.tableList = []
        return
      }
      
      let res = await axios.request({
        url: `report?adIds=${adQueryList}&beginTime=${beginTime}&endTime=${endTime}&cols=${queryStr}`
      });
      res = res.data
      if (res.code == 500) {
        this.$message({
          message: '网络开小差',
          type: 'warning'
        });
      } else {
        res = res.data
        Object.keys(res).forEach(key => {
          res[key]
          if (res[key].hasOwnProperty('ctr')) {
            res[key]['ctr'] = res[key]['ctr'] + '%'
          }
          if (res[key].hasOwnProperty('convertRate')) {
            res[key]['convertRate'] = res[key]['convertRate'] + '%'
            // res.key.convertRate = res.key.convertRate + '%'
          }
        })
        this.obj = res
      }

    },
    async getData(){
      this.rateIndex = JSON.parse(JSON.stringify(this.defaultRateIndex))
      this.rateValue = JSON.parse(JSON.stringify(this.defaultRateValue))
      this.tableList = JSON.parse(JSON.stringify(this.defaultList))
      await this.httpData()
      if(this.obj){
      Object.keys(this.obj).forEach(item => {
        if(this.obj[item].proportion === -1){
          this.obj[item].proportion = '全时投放'
        }else{
          if(this.obj[item].proportion === 0){
            this.obj[item].proportion = '无投放'
          }else{
            this.obj[item].proportion = `部分投放（${this.obj[item].proportion}）`
          }
        }
      })
      this.tableList.forEach((item)=>{
          Object.assign(item, this.obj[item.id]);
      })
      }
      //更改table的key
      this.tableKey++
    },  
    setting: function(){
      this.isShow = !this.isShow
      //记录设置前的defaultColumns
      this.tempColumns = JSON.parse(JSON.stringify(this.defaultColumns))
    },
    cancle: function(){
      //点击取消，恢复设置之前的defaultColumns
      this.defaultColumns = JSON.parse(JSON.stringify(this.tempColumns))
      this.isShow = false
    },
    ensure: function(){
      //点击确认，将当前的defaultColumns赋给selectColumns
      this.selectColumns = JSON.parse(JSON.stringify(this.defaultColumns))
      localStorage.setItem('selectColumns', JSON.stringify(this.defaultColumns));
      // document.cookie = 'selectColumns' + '=' + JSON.stringify(this.defaultColumns)
      // this.setCookie('selectColumns',JSON.stringify(this.defaultColumns),1000000000000000000)
      this.isShow = false
      this.selectLength = 1
      this.selectColumns.forEach(item => {
        if(item.isChecked){
          this.selectLength++
        }  
      })
      this.getData()
      //更改table的key
      // this.tableKey++
    },
    changeColumns: function (val, item) {
    },
    search: function () {
      this.getData()
    },
    renderHeader(h, { column, $index }) {
			// 新建一个 span
			let span = document.createElement('span');
			span.innerText = column.label;
      span.style.fontSize = 18 + 'px'
			document.body.appendChild(span);
      //计算表头的实际宽度
			column.minWidth = span.getBoundingClientRect().width + 30;
			document.body.removeChild(span);
			return h('span', column.label);
		},
    dateformart: function(time){
      let myyear = new Date(time).getFullYear()
      let mymonth = (new Date(time).getMonth() + 1) > 9 ? (new Date(time).getMonth() + 1) : '0' + (new Date(time).getMonth() + 1)
      let myday = (new Date(time).getDate() + 1) > 9 ? (new Date(time).getDate() + 1) : '0' + (new Date(time).getDate() + 1)
      return myyear + '-' + mymonth + '-' + myday
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '汇总';
          return;
        }
        // cpm               点击率      直接支付ROI    总支付ROI      转化率       投放情况
        let prop = column.property
        // if(prop === 'cpm' || prop === 'ctr' || prop==='directPayRoi' || prop==='totalPayRoi' || prop==='convertRate' || prop==='proportion'){
        //   return;
        // }

        if (prop === 'statCost') {
          this.rateIndex.statCostIndex = index   //这一项在table里有
          return
        }
        if (prop === 'showCnt') {
          this.rateIndex.showCntIndex = index    //这一项在table里有
          return
        }
        if (prop === 'cpm') {
          this.rateIndex.cpmIndex = index
          return
        }
        if (prop === 'clickCnt') {
          this.rateIndex.clickCntIndex = index    //这一项在table里有
          return
        }
        if (prop === 'ctr') {
          this.rateIndex.ctrIndex = index         //这一项在table里有,另外计算
          return
        }
        if (prop === 'payOrderAmount') {
          this.rateIndex.payOrderAmountIndex = index     //这一项在table里有
          return
        }
        if (prop === 'directPayRoi') {
          this.rateIndex.directPayRoiIndex = index     //这一项在table里有,另外计算
          return
        }
        if (prop === 'totalPayAmount') {
          this.rateIndex.totalPayAmountIndex = index     //这一项在table里有
          return
        }
        if (prop === 'totalPayRoi') {
          this.rateIndex.totalPayRoiIndex = index        //这一项在table里有,另外计算
          return
        }
        if (prop === 'convertCnt') {
          this.rateIndex.convertCntIndex = index         //这一项在table里有
          return
        }
        if (prop === 'convertCost') {
          this.rateIndex.convertCostIndex = index        //这一项在table里有,另外计算
          return
        }
        if (prop === 'convertRate') {
          this.rateIndex.convertRateIndex = index        //这一项在table里有,另外计算
          return
        }
        if (prop === 'proportion') {                    //投放情况不计算
          return
        }

        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          let temSum = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              // return prev + curr;
              
              let count = math.add(prev, curr);
              return count;
            } else {
              return prev;
            }
          }, 0);
          if(temSum !== parseInt(temSum)){
            sums[index] = temSum.toFixed(2)
          }else{
            sums[index] = temSum
          }          
        } else {
          sums[index] = 'N/A';
        }
      });
      
      if (columns.length) {
        let tempStatCost = 0,
            tempShowCnt = 0,
            tempClickCnt = 0,
            tempPayOrderAmount = 0,
            tempTotalPayAmount = 0,
            tempConvertCnt = 0
        this.tableList.forEach(item => {
          if(item.hasOwnProperty('statCost')){
            tempStatCost = math.add(tempStatCost, item.statCost)
          }  
          if(item.hasOwnProperty('showCnt')){
            tempShowCnt = math.add(tempShowCnt, item.showCnt)
          } 
          if(item.hasOwnProperty('clickCnt')){
            tempClickCnt = math.add(tempClickCnt, item.clickCnt)
          } 
          if(item.hasOwnProperty('payOrderAmount')){
            tempPayOrderAmount = math.add(tempPayOrderAmount, item.payOrderAmount)
          }  
          if(item.hasOwnProperty('payOrderAmount') && item.hasOwnProperty('indirectOrderPayGmv7Days')){
            let totalPayAmount = math.add(item.payOrderAmount,item.indirectOrderPayGmv7Days)
            tempTotalPayAmount = math.add(tempTotalPayAmount, totalPayAmount)
          } 
          if(item.hasOwnProperty('convertCnt')){
            tempConvertCnt = math.add(tempConvertCnt, item.convertCnt)
          }
        })
        this.rateValue.statCost = tempStatCost
        this.rateValue.showCnt = tempShowCnt
        this.rateValue.clickCnt = tempClickCnt
        this.rateValue.payOrderAmount = tempPayOrderAmount
        this.rateValue.totalPayAmount = tempTotalPayAmount
        this.rateValue.convertCnt = tempConvertCnt

        //statCostIndex
        if(this.rateIndex.statCostIndex){
          sums[this.rateIndex.statCostIndex] = this.rateValue.statCost
        } 
        //showCntIndex
        if (this.rateIndex.showCntIndex) { 
          sums[this.rateIndex.showCntIndex] = this.rateValue.showCnt      
        } 
        //clickCnt
        if (this.rateIndex.clickCntIndex) { 
          sums[this.rateIndex.clickCntIndex] = this.rateValue.clickCnt     
        }
        //payOrderAmount
        if (this.rateIndex.payOrderAmountIndex) { 
          sums[this.rateIndex.payOrderAmountIndex] = this.rateValue.payOrderAmount      
        }
        //totalPayAmount
        if (this.rateIndex.totalPayAmountIndex) { 
          sums[this.rateIndex.totalPayAmountIndex] = this.rateValue.totalPayAmount     
        }
        //convertCnt
        if (this.rateIndex.convertCntIndex) { 
          sums[this.rateIndex.convertCntIndex] = this.rateValue.convertCnt    
        }


        if (this.rateIndex.cpmIndex) {
          if(this.rateValue.showCnt == 0 || math.divide(this.rateValue.statCost,this.rateValue.showCnt) == 0){
            sums[this.rateIndex.cpmIndex] = 0
          }else{
            sums[this.rateIndex.cpmIndex] = (math.multiply(math.divide(this.rateValue.statCost,this.rateValue.showCnt),1000)).toFixed(2)
          }
          //sums[this.rateIndex.cpmIndex] = (sums[this.rateIndex.statCostIndex]/sums[this.rateIndex.showCntIndex]*1000).toFixed(2)
        }
        if (this.rateIndex.ctrIndex) {
          if(this.rateValue.showCnt == 0 || math.divide(this.rateValue.clickCnt,this.rateValue.showCnt) == 0){
            sums[this.rateIndex.ctrIndex] = 0 + '%'
          }else{
            sums[this.rateIndex.ctrIndex] = (math.multiply(math.divide(this.rateValue.clickCnt,this.rateValue.showCnt),100)).toFixed(2) + '%'
          }
          //sums[this.rateIndex.ctrIndex] = (this.rateValue.clickCnt/this.rateValue.showCnt*100).toFixed(2) + '%'
        }
        if (this.rateIndex.directPayRoiIndex) {
          if(this.rateValue.statCost == 0 || math.divide(this.rateValue.payOrderAmount,this.rateValue.statCost) == 0){
            sums[this.rateIndex.directPayRoiIndex] = 0
          } else{
            //sums[this.rateIndex.directPayRoiIndex] = (math.divide(this.rateValue.payOrderAmount,this.rateValue.statCost)).toFixed(2)
            sums[this.rateIndex.directPayRoiIndex] = (math.divide(this.rateValue.payOrderAmount,this.rateValue.statCost)).toFixed(2)
          } 
          //sums[this.rateIndex.directPayRoiIndex] = (this.rateValue.payOrderAmount/this.rateValue.statCost).toFixed(2)  
          //sums[this.rateIndex.directPayRoiIndex] = (sums[this.rateIndex.payOrderAmountIndex]/sums[this.rateIndex.statCostIndex]).toFixed(2)  
        }
        if (this.rateIndex.totalPayRoiIndex) { 
          if(this.rateValue.statCost == 0 || math.divide(this.rateValue.totalPayAmount,this.rateValue.statCost) == 0){
            sums[this.rateIndex.totalPayRoiIndex] = 0
          } else{
            sums[this.rateIndex.totalPayRoiIndex] = (math.divide(this.rateValue.totalPayAmount,this.rateValue.statCost)).toFixed(2)
          }        
        }
        if (this.rateIndex.convertCostIndex) {
          if(this.rateValue.convertCnt == 0 || math.divide(this.rateValue.statCost,this.rateValue.convertCnt) == 0){
            sums[this.rateIndex.convertCostIndex] = 0 + '%'
          } else{
            sums[this.rateIndex.convertCostIndex] = (math.divide(this.rateValue.statCost,this.rateValue.convertCnt)).toFixed(2)
          } 
          //sums[this.rateIndex.convertRateIndex] = (this.rateValue.convertCnt/this.rateValue.clickCnt*100).toFixed(2) + '%'
        }  
        if (this.rateIndex.convertRateIndex) {
          if(this.rateValue.clickCnt == 0 || math.divide(this.rateValue.convertCnt,this.rateValue.clickCnt) == 0){
            sums[this.rateIndex.convertRateIndex] = 0 + '%'
          } else{
            sums[this.rateIndex.convertRateIndex] = (math.multiply(math.divide(this.rateValue.convertCnt,this.rateValue.clickCnt),100)).toFixed(2) + '%'
          } 
          //sums[this.rateIndex.convertRateIndex] = (this.rateValue.convertCnt/this.rateValue.clickCnt*100).toFixed(2) + '%'
        }  
      }
      return sums;
    },
    changeStyle({ row, column, rowIndex, columnIndex }) {
      if(columnIndex == 0 || column.property=='proportion'){
        return
      }else{
        if ( row[column.property] > 0 || (typeof row[column.property] == 'string' && row[column.property] != '0%' && row[column.property].length > 1) ) {
          return 'background-color: #e7eaee'
        }
      }
    },
    selectAll(checked){
      this.defaultColumns.forEach(item => {
        if(item.prop != 'proportion'){
          item.isChecked = checked
        }
      })
    },
    setCookie(name, value, times){    
        let date = new Date()
        date.setDate(date.getTime() + times)    
        document.cookie = name + '=' + value + ';expires=' + date.toGMTString()
    },
    getCookie(name){    
      let cookies = document.cookie    
      let cookieArr = cookies.split(';') || []
      if(!cookieArr.length) return ''
      for(let i = 0; i < cookieArr.length; i++) {        
          let arr = cookieArr[i].split('=')        
          if (name == arr[0] ) {            
              return arr[1]
          }
      }    
      return false
  }
  }
};
</script>

<style lang="less" scoped>
.home {
  padding: 10px 40px;
  .select {
    display: flex;
    margin-bottom: 10px;
    .row {
      display: flex;
      align-items: center;
    }

    .select-item {
      margin-right: 20px;
      .el-select{
        min-width: 300px;
      }
    }
    .setting{
      position: absolute;
      top: 20px;
      right: 40px;
      .board{
        position: fixed;
        // width: 450px;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        margin: auto;
        background: white;
        border-radius: 2px;
        box-shadow: 0 1px 3px #92a3ab;
        z-index: 99999;
        text-align: right;
        // .check{
        //   text-align: left;
        //   .columns{
        //     min-width: 165px;
        //     text-align: left;
        //     margin-bottom: 10px;
        //   }
        // }
        .close{
          padding: 5px 10px 5px 0;
          height: 22px;
          border: 1px solid #e5e5e5;
          // border-radius: 2px;
          // box-shadow: 0 1px 3px #92a3ab;
          span{
            font-size: 20px;
            color: #409EFF;
            cursor: pointer;
          }
        }
        .check{
          margin-left: 10px;
          display: flex;
          text-align: left;
          margin-bottom: 15px;
          max-height: 440px;
          overflow-y: auto;
          .left{
            width: 100px;
            border-right: 1px solid #000;
            .tab{
              line-height: 70px;
            }
          }
          .right{
            margin-left: 20px;
            width: 400px;
            .select-all{
              margin-top: 15px;
            }
            .tab-body{
              h5{
                margin: 10px 0;
              }
              .tab-body-item{
                display: flex;
                flex-wrap: wrap;
                .check-item{
                  flex: 0 0 33.3%;
                }
              }
            }
          }
          .columns{
            min-width: 165px;
            text-align: left;
            margin-bottom: 10px;
          }
        }
        .setting-button{
          text-align: right;
          height: 28px;
          margin-right: 10px;
          margin-bottom: 10px;
        }
      }
    }
  }
  .container{
    margin-bottom: 10px;
    .table-list{
      border: 1px solid #eef1f6;
      // font-size: 18px;
    }
  }

}

</style>

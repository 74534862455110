<template>
  <div class="warning">
    <h3>电商数据监测  <span class="iconfont icon-warning header-icon" style="color: green"></span><span class="iconfont icon-warning header-icon" style="color: yellow"> </span><span class="iconfont icon-warning header-icon" style="color: red"></span></h3>
    <el-table
      :data="tableData"
      :span-method="objectSpanMethod"
      :cell-style="{padding: '5px 0'}"
      :header-cell-style="{background:'#eef1f6',color:'#606266'}"
      border
      style="wplatformth: 100%; margin-top: 20px">
      <template>
        <el-table-column v-for="(item) in defaultColumns" :label=item.label :prop=item.prop :key="item.prop" align="center">
            <template slot-scope="scope">
              <span v-if="item.prop !== 'status'">{{ scope.row[item.prop] }}</span>
              <span v-else class="iconfont icon-warning" :style="{'color': scope.row.status == 0 ? 'green' : (scope.row.status == 1 ? 'yellow' : 'red')}"></span>
            </template>
        </el-table-column>
      </template>
    </el-table>
    <!-- <el-table
      class="small-table"
      v-show="smallShow"
      :data="smallTableData"
      :span-method="objectSpanMethod"
      :cell-style="{padding: '5px 0'}"
      :header-cell-style="{background:'#eef1f6',color:'#606266'}"
      border
      style="wplatformth: 100%; margin-top: 20px">
      <template>
        <el-table-column v-for="(item) in defaultColumns" :label=item.label :prop=item.prop :key="item.prop" align="center">
            <template slot-scope="scope">
              <span v-if="item.prop !== 'status'">{{ scope.row[item.prop] }}</span>
              <span v-else class="iconfont icon-warning" :style="{'color': scope.row.status == 0 ? 'green' : (scope.row.status == 1 ? 'yellow' : 'red')}"></span>
            </template>
        </el-table-column>
      </template>
    </el-table> -->
  </div>
</template>

<script>
import axios from "@/utils/http.js";
export default {
    data() {
      return {
        defaultColumns: [
          // {
          //   label: '区域', prop: 'area'
          // },
          {
            label: '平台', prop: 'platform'
          },
          {
            label: '店铺', prop: 'name'
          },
          {
            label: '推广渠道', prop: 'promotion'
          },
          {
            label: '状态', prop: 'status'
          }
        ],
        defaultTableData: [
        {
          //area: '境内',
          platform: '天猫',
          name: '天猫旗舰店',
          promotion: '直通车',
          type: 'tmall',
          status: 0,
        }, {
          //area: '境内',
          platform: '天猫',
          name: '天猫旗舰店',
          promotion: '万相台',
          type: 'tm_adbrain',
          status: 0,
        }, {
          //area: '境内',
          platform: '天猫',
          name: '天猫专卖店',
          promotion: '直通车',
          type: 'tmzm_through_train',
          status: 0,
        }, {
          //area: '境内',
          platform: '天猫',
          name: '天猫专卖店',
          promotion: '万相台',
          type: 'tmzm_adbrain',
          status: 0,
        },
        {
          //area: '境内',
          platform: '京东',
          name: '京东旗舰店',
          promotion: 'RTB',
          type: 'jd_rtb',
          status: 0,
        }, {
          //area: '境内',
          platform: '京东',
          name: '京东专卖店',
          promotion: 'RTB',
          type: 'jdzm_rtb',
          status: 0,
        }, {
          //area: '境内',
          platform: '京东',
          name: '京东自营店',
          promotion: 'RTB',
          type: 'jdzy_rtb',
          status: 0,
        },
        // {
        //   //area: '境内',
        //   platform: '拼多多',
        //   name: '拼多多旗舰店',
        //   promotion: '多多搜索',
        // }, {
        //   //area: '境内',
        //   platform: '拼多多',
        //   name: '拼多多旗舰店',
        //   promotion: '多多场景',
        // }, {
        //   //area: '境内',
        //   platform: '拼多多',
        //   name: '拼多多旗舰店',
        //   promotion: '放心推',
        // }, {
        //   //area: '境内',
        //   platform: '拼多多',
        //   name: '拼多多旗舰店',
        //   promotion: '全站推广',
        // }, {
        //   //area: '境内',
        //   platform: '拼多多',
        //   name: '拼多多专卖店',
        //   promotion: '多多搜索',
        // }, {
        //   //area: '境内',
        //   platform: '拼多多',
        //   name: '拼多多专卖店',
        //   promotion: '多多场景',
        // }, {
        //   //area: '境内',
        //   platform: '拼多多',
        //   name: '拼多多专卖店',
        //   promotion: '放心推',
        // },{
        //   //area: '境内',
        //   platform: '拼多多',
        //   name: '拼多多专卖店',
        //   promotion: '全站推广',
        // },
        {
          //area: '境内',
          platform: '抖音',
          name: '抖音旗舰店',
          promotion: '直播数据',
          type: 'douyin_flow',
          status: 0
        },{
          //area: '境内',
          platform: '抖音',
          name: '抖音旗舰店',
          promotion: '短视频/图文数据',
          type: 'douyin_svideo',
          status: 0,
        },
        {
          //area: '境内',
          platform: '得物',
          name: '得物',
          promotion: '得物',
          type: 'dewu',
          status: 0,
        },{
          //area: '境内',
          platform: '小红书',
          name: '小红书',
          promotion: '效果广告',
          type: 'xhs',
          status: 0,
        },
        // {
        //   area: '美国',
        //   platform: '亚马逊',
        //   name: 'Chiline',
        //   promotion: '广告活动',
        // },{
        //   area: '台北',
        //   platform: '虾皮',
        //   name: 'chiline.tw',
        //   promotion: '虾皮广告',
        // },{
        //   area: '台北',
        //   platform: 'FaceBook',
        //   name: 'Chiline泫音',
        //   promotion: '广告系列',
        // },{
        //   area: '台北',
        //   platform: 'Google Analytics',
        //   name: 'Chiline',
        //   promotion: 'Google Ads 广告系列)',
        // }
        ],
        tableData: [],
        smallShow: false,
        smallTableData: []
      };
    },
    created(){
      this.getData(this)
    },
    mounted(){
      this.loop()
    },
    methods: {
      async getData(source){
        source.tableData = JSON.parse(JSON.stringify(source.defaultTableData))
        source.smallTableData = []
        let res = await axios.request({
          url: `http://10.150.254.247:9001/platforms`
        })
        res = res.data
        source.tableData.forEach((item,index) => {
          res.data.forEach((resItem,resindex) => {
            if (item.type == resItem.type) {
              item.status = resItem.status
              if (item.status != 0) {
                source.smallTableData.push(item)
              }
            }
          })
        })
        source.smallTableData.push({
          //area: '境内',
          platform: '天猫',
          name: '天猫旗舰店',
          promotion: '直通车',
          type: 'tmall',
          status: 2,
        },)
        if (source.smallTableData.length) {
           source.smallShow = true
        }
      },
      loop(){
        let _this = this
        setInterval(() => {
          setTimeout(function(){
            _this.$options.methods.getData(_this)
          }, 0);
        }, 600000)
      },
      objectSpanMethod({ row, column, rowIndex, columnIndex }) {
          // 对第一列 第二列 进行合并
          if (columnIndex === 1 || columnIndex === 1 || columnIndex === 0) {
              // 当 当前行与上一行内容相同时 返回0 0 意味消除
              if(rowIndex > 0 && row[column.property] === this.tableData[rowIndex - 1][column.property]){
                  return {
                      rowspan: 0,
                      colspan: 0
                  };
              }else{
                  let rows = 1;
                  // 反之 查询相同的内容有多少行 进行合并
                  for(let i = rowIndex; i < this.tableData.length - 1; i++){
                      if (row[column.property] === this.tableData[i + 1][column.property]) {
                          rows++;
                      }
                  }
                  // 返回相同内容的行数
                  return {
                      rowspan: rows,
                      colspan: 1
                  };
              }
          }
      },
      changeStyle({ row, column, rowIndex, columnIndex }) {
        return 'padding: 5px 0'
      },
    }
}
</script>

<style lang="less" scoped>
.warning{
  .header-icon{
    margin: 0 5px;
  }
  width: 80%;
  margin: 0 auto;
  /* display: flex;
  flex-direction: column;
  align-items: center; */
  // .small-table{
  //   position:fixed;
  //   margin:0 auto;
  //   left:0;
  //   right:0;
  // }
}
</style>
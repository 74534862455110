import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Warning from "../views/Warning.vue";
import Horn from "../views/Horn.vue";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/:id?",
  //   name: "Home",
  //   component: Home,
  // },
  // {
  //   path: "/",
  //   name: "Home",
  //   component: Home,
  //   meta: {
  //     title: '抖音短视频/图文数据'
  //   }
  // },
  {
    path: "/",
    name: "Horn",
    component: Horn,
    meta: {
      title: "抖音喇叭",
    },
  },
  {
    path: "/warning",
    name: "Warning",
    component: () => import("../views/Warning.vue"),
    meta: {
      title: "电商数据警报",
    },
  },
  {
    path: "/select",
    name: "Select",
    component: () => import("../views/SelectAll.vue"),
  },
  {
    path: "/selectnew",
    name: "Select",
    component: () => import("../views/SelectAllNew.vue"),
  },
  {
    path: "/monitor",
    name: "Monitor",
    component: () => import("../views/monitor.vue"),
    meta: {
      title: "电商数据监测",
    },
  },
  // {
  //   path: "/horn",
  //   name: "Horn",
  //   component: () => import('../views/Horn.vue'),
  //   meta: {
  //     title: '抖音喇叭'
  //   }
  // },
  {
    path: "/bi",
    name: "Bi",
    component: () => import("../views/Bi.vue"),
    meta: {
      title: "格洛-销售BI大屏",
    },
  },
  {
    path: "/amazon",
    name: "Amazon",
    component: () => import("../views/Amazon.vue"),
    meta: {
      title: "亚马逊文件上传",
    },
  },
  // {
  //   path: "/upload",
  //   name: "Upload",
  //   component: () => import('../views/AmazonUpload.vue'),
  //   meta: {
  //     title: '文件上传'
  //   }
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});
export default router;

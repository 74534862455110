import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import "./plugins/echarts";
import "./plugins/element.js";
import "@/assets/font/iconfont.css";
// import * as math from 'mathjs'
// 引入echarts
//import echarts from 'echarts'


Vue.prototype.openLoading = function() {
  const loading = this.$loading({           // 声明一个loading对象
    lock: true,                             // 是否锁屏
    text: '加载中',                         // 加载动画的文字
    spinner: 'el-icon-loading',             // 引入的loading图标
    background: 'rgba(0, 0, 0, 0.8)',       // 背景颜色
    target: '.bi',       // **需要遮罩的区域，这里写要添加loading的选择器**
    fullscreen: true,
    // customClass: 'loadingclass'             // **遮罩层新增类名，如果需要修改loading的样式**
  })
  setTimeout(function () {                  // 设定定时器，超时5S后自动关闭遮罩层，避免请求失败时，遮罩层一直存在的问题
    loading.close();                        // 关闭遮罩层
  },5000)
  return loading;
}


Vue.config.productionTip = false;

// Vue.prototype.$math = math; 
//Vue.prototype.$echarts = echarts

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

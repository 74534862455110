<template>
  <div class="home">
		<div class="home-header">
			<span>时间：{{nowTime}}</span>
			<p>
				<span>人数：{{nofilterNum}}+</span>
      	<a href="javascript:void(0)" @click="showFilter()" style="color:blue">{{filterNum}}</a>
			</p>
		</div>
    <el-table
      :data="tableData"
      style="{width: 100%,table-layout: fixed}"
      height="650"
      :header-cell-style="{background:'#eef1f6',color:'#606266'}"
      
    >
      <!-- <el-table-column
        align="center"
        type="index"
        width="98"
      >
      <template slot="header">
        <span>人数：{{nofilterNum}}+</span>
        <a href="javascript:void(0)" @click="showFilter()" style="color:blue">{{filterNum}}</a>
      </template>
      </el-table-column> -->
      <el-table-column
        prop="nickname"
        label="ID"
        align="left">
        <template slot-scope="scope">
          <!-- 老接口的状态isBuy -->
          <div class="fans-icon">
            <img v-if="scope.row.isfan == 1" type="text" size="small" src="~@/assets/fans_new.png">
            <img v-if="scope.row.isfan == 2" type="text" size="small" src="~@/assets/fans_old.png">
          </div>
          <span v-if="scope.row.isrepeat == true" style="color: red">{{scope.row.nickname}}</span>
          <span v-if="scope.row.isrepeat == false">{{scope.row.nickname}}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column
        width="auto"
        min-width="40%"
        align="center"
        prop="online_time"
        label="停留时间">
      </el-table-column> -->
      <el-table-column
        align="center"
        label="历史订单"
        >
        <template slot-scope="scope">
          <!-- <img v-if="scope.row.status == 1" type="text" size="small" src="~@/assets/buy.png">
          <img v-if="scope.row.status == 2" type="text" size="small" src="~@/assets/before.png">
          <img v-if="scope.row.status == 4" type="text" size="small" src="~@/assets/wait.png"> -->
					<span v-for="item in scope.row.orders" :key="item.index" :style="{ color: getStatusColor(item.status), margin: '0 5px' }">
						<span v-for="skuItem in item.sku_show_name" :key="item.index">{{skuItem}}</span>
					</span>
        </template>
      </el-table-column>
    </el-table>
    <div id="filter" v-show="show">
      <div class="close"><span class="iconfont icon-close close-span" @click="close"></span></div>
      <el-table
      id="filter-table"
      :data="filterArr">
        <el-table-column
          prop="nickname"
          label="ID"
          align="left">
          <template slot-scope="scope">
            <!-- 老接口的状态isBuy -->
            <div class="fans-icon">
              <img v-if="scope.row.isfan == 1" type="text" size="small" src="~@/assets/fans_new.png">
              <img v-if="scope.row.isfan == 2" type="text" size="small" src="~@/assets/fans_old.png">
            </div>
            <span v-if="scope.row.isrepeat == true" style="color: red">{{scope.row.nickname}}</span>
            <span v-if="scope.row.isrepeat == false">{{scope.row.nickname}}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column
          width="auto"
          min-width="50%"
          align="center"
          prop="online_time"
          label="停留时间">
        </el-table-column> -->
        <el-table-column
          width="auto"
          min-width="40%"
          align="center"
          label="历史订单"
          >
          <template slot-scope="scope">
            <!-- <img v-if="scope.row.status == 1" type="text" size="small" src="~@/assets/buy.png">
            <img v-if="scope.row.status == 2" type="text" size="small" src="~@/assets/before.png">
            <img v-if="scope.row.status == 4" type="text" size="small" src="~@/assets/wait.png"> -->
						<span v-for="item in scope.row.list" :key="item.index" :style="{ color: getStatusColor(item.status) }">
							<span v-for="skuItem in item.sku_code" :key="item.index">{{skuItem}}"></span>
						</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import axios from "@/utils/http.js";
let page = 1
  export default {
    name: 'home',
    data() {
      return {
        buyWidth: 100,
        screenWidth: document.body.clientWidth,
        model: '',
        numLabel: '人数:',
        // totalNum: null,
        filterNum: null,
        nofilterNum: null,
        nowTime: null,
        tableData: [
        ],
        show: false,
        filterArr: [],
        unfilterArr: [],
        // tableData: []
      }
    },
    created(){
      this.totalNum = this.numLabel
      this.getVisitors(this)
    },
    mounted(){
      const that = this
      window.onresize = () => {
          return (() => {
              window.screenWidth = document.body.clientWidth
              if (window.screenWidth < 900) {
                that.buyWidth = 50
              }
          })()
      }
      this.loop()
    },
    methods: {
      // over() {
      //   this.show = true
      // },
      // leave() {
      //   this.show = false
      // },
      showFilter(){
        this.show = !this.show
      },
      close(){
        this.show = !this.show
      },
      async getVisitors(source){
        // source.filterArr = []
        // source.nofilterArr = []
        let _filterArr = []
        let _nofilterArr = []
        try {
					console.count('getVisitors')
          let orderData = await axios.request({
            url: `https://douyin-laba-api-v2.ia.net.cn/api/douyin`
          });
          orderData = orderData.data
					// 打印下面的forEach执行消耗的时间
					orderData.data.forEach(item => {
						if (item.filter == 1) {
							item.orders = []
						}
					})
					let { data } = await axios.request({
            url: `https://topapi.xuan-you.com/console/accelerator/thirdPlatformOrder/getOrderSku`,
						method: 'post',
						data: orderData.data
          });
         	data = data.data
          data.forEach(item => {
            item.online_time = source.format(item.online_time)
            if (item.filter == 1) {  
              _filterArr.push(item)
            }
            if (item.filter == 0) {     //未过滤
              _nofilterArr.push(item)
            }
            source.filterArr = _filterArr
            source.nofilterArr = _nofilterArr
          })
          source.tableData = source.nofilterArr
          source.nowTime = source.$options.methods.GMTToStr(source,orderData.date)
          // source.nowTime = res.date
          source.filterNum = source.filterArr.length
          source.nofilterNum = source.nofilterArr.length
          // source.totalNum = ''
          // source.totalNum = source.numLabel + source.nofilterArr.length + '+' + source.filterArr.length
          // source.nowTime = source.$options.methods.GMTToStr(source,"Sun, 26 Jun 2022 13:25:04 GMT")
        } catch (error) {
          throw new Error(error);
        }

      },
			getStatusColor(status) {
				// <!-- <img v-if="scope.row.status == 1" type="text" size="small" src="~@/assets/buy.png">
        //   <img v-if="scope.row.status == 2" type="text" size="small" src="~@/assets/before.png">
        //   <img v-if="scope.row.status == 4" type="text" size="small" src="~@/assets/wait.png"> -->
				if (status === 1) {
					return 'green';
				} else if (status === 2) {
					return 'blue';
				} else if (status === 4) {
					return 'red';
				}
				// 默认颜色
				return 'black';
			},
      // loop(){
      //   let _this = this
			// 	console.log(_this.$options)
      //   setInterval(() => {
      //     setTimeout(function(){
      //       _this.$options.methods.getVisitors(_this)
      //     }, 0);
      //   }, 2000)
      // },
			loop(){
        let _this = this
				console.log(_this.$options)
				this.getVisitors(this)
				setTimeout(() => {
					console.log(_this.$options)
					_this.loop()
				}, 2000);
      },
      format(seconds) {
        let hour = Math.floor(seconds / 3600) >= 10 ? Math.floor(seconds / 3600) : '0' + Math.floor(seconds / 3600);
        seconds -= 3600 * hour;
        let min = Math.floor(seconds / 60) >= 10 ? Math.floor(seconds / 60) : '0' + Math.floor(seconds / 60);
        seconds -= 60 * min;
        let sec = seconds >= 10 ? seconds : '0' + seconds;
        return hour + ':' + min + ':' + sec;
      },
      // timeFormat(source,time){
      //   let timestamp = new Date(time).getTime() + 8*3600*1000
      //   source.$options.methods.GMTToStr(source,timestamp)
      // },
      GMTToStr(source,time){  // 将 GMT格式的时间字符串 格式化为正常时间字符串
          let timestamp = new Date(time).getTime() + 8*3600*1000
          let date = new Date(timestamp)
          let Str=date.getFullYear() + '/' +
          (source.$options.methods.handleTimeStr(date.getMonth() + 1)) + '/' +
          source.$options.methods.handleTimeStr(date.getDate()) + ' ' +
          source.$options.methods.handleTimeStr(date.getHours()) + ':' +
          source.$options.methods.handleTimeStr(date.getMinutes()) + ':' +
          source.$options.methods.handleTimeStr(date.getSeconds())
          return Str
      },
      handleTimeStr(tStr){  // 解决 10:2:2 出现各位数字格式化为 10:02:02
        if (tStr < 10){
          return '0'+ tStr
        }else {
          return tStr
        }
      },
      // changeCellStyle (row, column, rowIndex, columnIndex) {
      // //列的label的名称
      //   if (row.column.label === "状态") {
      //     return 'padding: 0' //修改的样式
      //   } else {
      //     return ''
      //   }
      // },
      //rowIndex是第几行，columnIndex是第几列

    }
  }
</script>

<style>
.home-header{
	display: flex;
	align-items: center;
	justify-content: space-around;
  height: 50px;
  background: #f5f7fa;
  border-bottom: 1px solid #ebeef5;
}

#filter{
  width: 340px;
  background: #fff;
  border: 1px solid #ebeef5;
  position: fixed;
  top: 50%;
  left: 50%;
  margin: auto;
  transform: translate(-50%, -50%);
}
#filter .close{
  text-align: right;
  /* color: rgb(76, 0, 255); */
  background: #a2a2ef;
}
#filter .close .close-span{
  font-size: 20px;
  margin-right: 10px;
  cursor: pointer;
}
#filter #filter-table{
  height: 90vh;
  overflow-y: auto;
}
#filter .el-table__cell{
  background: #c9c9f6;
}
/* #filterlist{
  position: fixed;
  left: 50%;
  transform: translate(-50%,0);
  top: 100px;
}
#filterlist li{
  list-style: none;
  border-bottom: 1px solid grey;
} */
.el-table--striped .el-table__body tr.el-table__row--striped td{
 /* background-color: #ecf3fd; */
 background-color: #000;
}
/* .right{
  display: flex;
} */
p{
  margin: 0;
  padding: 0;
  text-align: left;
}




.fans-icon{
  display: inline-block;
  /* width: 26px; */
  vertical-align: middle;
}
img{
  width: 26px;
  height: 26px;
  margin-right: 4px;
  /* margin-top: 5px; */
}

@media screen and (max-width: 900px) {
  #app {
    padding: 0 10px
  }
  input{
    height: 10px;
    padding: 0;
  }
  p{
    line-height: 22px;
    font-size: 22px;
    /* display: inline-block; */
    margin: 10px;
		display: flex;
		justify-content: space-around;
		align-items: center;
  }
  /* td:nth-child(4){
    width: 50px;
  } */
  /* #filter{
    left: 0;
    right: 1%;
    transform: translate(0,-50%);
  } */
}

</style>

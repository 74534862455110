import Vue from 'vue'
import { Button,  Checkbox,Col, DatePicker, Popover, Option,Row,Select,Table,
  TableColumn, Tag, Tooltip, Message,Loading,Upload,Form,FormItem,Input } from 'element-ui'
// import Plugin from 'v-fit-columns'


Vue.use(Checkbox)
Vue.use(Col)
Vue.use(DatePicker)
Vue.use(Button)
Vue.use(Popover)
Vue.use(Option)
Vue.use(Row)
Vue.use(Select)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Tag)
Vue.use(Tooltip)
Vue.use(Loading);
Vue.use(Upload);
Vue.use(Form);
Vue.use(Input);
Vue.use(FormItem);
// Vue.use(MessageBox)
Vue.prototype.$message = Message;
// Vue.use(Plugin)

<template>
  <div id="app">
    <router-view :key="key"></router-view>
  </div>
</template>

<script>

export default {
  name: "app",
  computed: {
    key(){
      console.log(this.$route.path)
      return this.$route.path + new Date().getTime()
    }
  }
};
</script>

<style>
* {
    box-sizing: border-box;
}
*::before,*::after{
  box-sizing: border-box;
}

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 10px; */
}
</style>
